import { Outlet, Navigate } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { useAuthStatus } from '../../resources/hooks/authHook'
import { useSelector } from 'react-redux';
import { getAppType } from '../../store/settings/settings.selectors';
import PublicChatWindow from '../../pages/publicChat';

const PrivateRoutes = () => {
    const isAuthenticated = useAuthStatus();
    const isPublic = useSelector(getAppType);

    return(
        isPublic ? <PublicChatWindow/> : isAuthenticated ? <Outlet/> : <Navigate to={URLS.LOGIN} />
    )
}

export default PrivateRoutes