import { useState } from 'react'
import FormTextInput from './textInput'
import { IFormTextInputProps } from '../../resources/interfaces/form-elements'

const PasswordInput = (props: IFormTextInputProps) => {
    const [inputType, setInputType] = useState<string>('password')
    return (
        <FormTextInput
            {...props}
            type={inputType}
            icon={inputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'}
            onIconClick={() => setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))}
        />
    )
}

export default PasswordInput