import { FC, useState } from "react";
import ChatHistory from "../../components/chat/chatHistory";
import { useDispatch } from "react-redux";
import { authenticateUser, updateAccessToken, updateUser } from "../../store/user/user.slice";
import { useNavigate } from "react-router-dom";
import { getAppTheme, sidebarVisible } from "../../store/settings/settings.selectors";
import { useSelector } from "react-redux";
import { setSidebarVisible } from "../../store/settings/settings.slice";

const SideNavbar: FC = () => {
  const today = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector(getAppTheme);
  const isSidebarVisible = useSelector(sidebarVisible)

  const handleLogout = () => {
    dispatch(updateUser({}))
    dispatch(authenticateUser(false))
    dispatch(updateAccessToken(""))
    navigate("/login")
  }

  const closeSidebar = (evt: React.MouseEvent) => {
    evt.preventDefault()
    dispatch(setSidebarVisible(false))
  }

  return (
    <div className={isSidebarVisible ? `left-bar-wrap left-on` : `left-bar-wrap`} style={{'backgroundColor': theme.menuBackgroundColor, color: theme.menuTextColor}}>
      <div className="logo-wrap">
        <img
          src={theme.logoURL}
          alt=""
        />
      </div>
      <div className="close-sidebar" onClick={closeSidebar}><i className="fa-solid fa-xmark"></i></div>
      <ChatHistory />
    </div>
  );
};

export default SideNavbar;
