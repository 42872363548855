import ChatWindowOld from "../../components/chat/chatWindowOld";
import CreateThreadForm from "../../components/chat/newChatForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getActiveSessionId, getActiveThreadObject } from "../../store/chat/chat.selectors";
import { setActiveSessionId, setActiveThreadObject } from "../../store/chat/chat.slice";
import { CallApi } from "../../saga/callApi";
import { API_URLS, INACTIVITY_TIMEOUT_SECONDS } from "../../resources/constants";
import { getActiveAppId } from "../../store/settings/settings.selectors";
import useTimer from "../../resources/hooks/timerHook";

const PrivateChatWindow: React.FC = () => {
    const { threadId } = useParams();
    const dispatch = useDispatch()
    const activeAppId = useSelector(getActiveAppId)
    const sessionId = useSelector(getActiveSessionId)
    const activeThreadObject = useSelector(getActiveThreadObject(threadId ?? ''))
    const timer = useTimer()
  
    useEffect(() => {
      if (threadId) {
        // Call init API
        initChatSession()
      }
    }, [threadId, activeAppId])

    // useeffect for failsafe timer
    useEffect(() => {
      if ( timer.seconds >= INACTIVITY_TIMEOUT_SECONDS ) {
        timer.stopTimer()
        initChatSession()
      }
    }, [timer])
  

    const initChatSession = () => {
      return CallApi.POST(API_URLS.CHAT.INIT, {
        appId: activeAppId,
        session_id: sessionId,
        thread_id: threadId
      })
        .then((resp: any) => {
          // console.log({ initApi: resp })
          if (resp?.data?.session_id) {
            // setActiveThreadId(resp.data.session_id)
            dispatch(setActiveSessionId(resp.data.session_id))
            dispatch(setActiveThreadObject(threadId ?? ''))
          }
        }).catch((error: any) => {
          console.log('init api error', error)
        })
    }
    
    return (
      <div className="right-container">
        {
          activeThreadObject ? <ChatWindowOld threadId={activeThreadObject.chat_thread_id} sessionId={sessionId} /> : <CreateThreadForm />
        }
      </div>
    );
}

export default PrivateChatWindow