import { RootState } from '../types'

export const getChatMessages = (threadId?: string) => (state: RootState) =>
    threadId
        ? state.chats.conversations[threadId]
            ? state.chats.conversations[threadId]
            : []
        : state.chats.conversations['default']

export const getActiveThreadObject = (threadId: string) => (state: RootState) => state.chats.threads.find(thread => thread.chat_thread_id === threadId)

export const getChatThreads = (state: RootState) => state.chats.threads

export const getActiveSessionId = (state: RootState) => state.chats.activeSessionId

export const getAudioPlayerPlaying = (state: RootState) => state.chats.isAudioPlaying

export const getIsSessionEnded = (state: RootState) => state.chats.isSessionEnded