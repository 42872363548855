import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAppTheme } from '../../store/settings/settings.selectors';

const Examples = () => {
    
    const dispatch = useDispatch()
    const theme = useSelector(getAppTheme)
    const examples = theme.examples

    
    return (
        <div className='new-chat-form-wrap pop-selection'>
            <div className="intro" >Some examples you can use to try the app!</div>
            <ul>
                {examples?.map((item:any, index:any) => (
                    <li key={index}>
                        <i className="fa-solid fa-circle-info"></i> {item['sampleQuestion']}
                    </li>
                ))}
            </ul>
        </div>
    )
}   

export default Examples