
import { call, put } from "redux-saga/effects";
import { authenticateUser, updateAccessToken } from "../store/user/user.slice";
import { API_URLS } from "../resources/constants";
import { CallApi } from './callApi'

export function* login(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.LOGIN, payload);
    if (result && result.data.error === false) {
      yield put(authenticateUser(true));
      yield put(updateAccessToken(result.data.accessToken));
      action && action.callbackSuccess(result);
    } else {
      action && action.callbackError(new Error('Unauthorized Access'));
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}



