import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import AppNotFound from '../pages/appNotFound'
import { parseValidAppId } from '../resources/helper'
import { CallApi } from '../saga/callApi'
import { API_URLS, APP_AUDIO_LANGUAGES } from '../resources/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveAppId, setAppState, updateAppType, setAppTheme, setPhraseHints, setAuthApiKey, setVoiceLanguage } from '../store/settings/settings.slice'
import { getAppState } from '../store/settings/settings.selectors'
import { setAppLoader } from '../store/appLoader/appLoader.slice'
import { setActiveSessionId } from '../store/chat/chat.slice'
import { AppThemeContext } from '../resources/contexts/appTheme'
import { IAppThemeContext } from '../resources/interfaces/settings.interface'
import { ChatContext } from '../resources/contexts/chat'
import { IChatContext } from '../resources/interfaces/chat.interface'

const AppTypeIdentifierHOC: React.FC = () => {
    const { appId: urlAppId } = useParams()
    const appId = parseValidAppId(urlAppId)
    const dispatch = useDispatch()
    const appState = useSelector(getAppState)
    const navigate = useNavigate()
    const { setTheme, setLayout } = useContext(AppThemeContext) as IAppThemeContext
    const { setPhraseHints } = useContext(ChatContext) as IChatContext


    useEffect(() => {
        if (appId && appId.length > 0) {
            dispatch(setAppLoader(true))
            CallApi.GET(API_URLS.SETTINGS(appId))
            .then((settingsResponse: any) => {
                const phrasehints = settingsResponse['data']['utterances']
                const settingsData = settingsResponse['data']['app_settings'][0];
                const isPublicApp: boolean = settingsData['appType'] == 2 ? true : false
                dispatch(setAuthApiKey(settingsResponse?.data?.bb_api_key))
                dispatch(setActiveAppId(appId))
                dispatch(updateAppType(isPublicApp))
                dispatch(setAppState(isPublicApp ? 'public' : 'private'))
                // dispatch(setActiveSessionId())
                dispatch(setAppLoader(false))
                setPhraseHints(phrasehints)
                setTheme({
                    appTitle: settingsData['pageTitle'],
                    logoURL: settingsData['logoURL'],
                    menuBackgroundColor: settingsData['menuBackgroundColor'],
                    menuTextColor: settingsData['menuTextColor'],
                    headerBackGroundColor: settingsData['headerBackGroundColor'],
                    headerTextColor: settingsData['headerTextColor'],
                    footerBackgroundColor: settingsData['footerBackgroundColor'],
                    footerTextColor: settingsData['footerTextColor'],
                    otherBackgroundColor: settingsData['otherBackgroundColor'],
                    otherTextColor: settingsData['otherTextColor'],
                    copyRightText: settingsData['copyRightText'],
                    examples: settingsData['_objSampleQuestions']
                })
                dispatch(setVoiceLanguage(APP_AUDIO_LANGUAGES[0]));
                setLayout('public')
                navigate(isPublicApp ? '' : 'login')
            })
            .catch((error: any) => {
                console.log("error", error)
                dispatch(setAppLoader(false))
            })
        }
        else {
            dispatch(setAppState(null))
        }
    }, [appId])

    return (
        appState && appState?.length > 0 ? <Outlet /> : <AppNotFound />
    )
}

export default AppTypeIdentifierHOC