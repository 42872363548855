import React, { useEffect, useRef, useState } from 'react';
import { IAudioMessage } from '../../resources/interfaces/chat.interface';

interface IAudioPlayerProps {
    audioEnabled: boolean;
    audioPlaying: boolean;
    trackIndex: number;
    playlist: IAudioMessage[];
    // play: boolean; // Prop to control playing or stopping the audio
    onUpdate: (id: string, payload: any) => void
    onSetTrackIndex: (index: number) => void
    onSetAudioPlaying: (val: boolean) => void
    onClearQueue: () => void
}

const AudioPlayer: React.FC<IAudioPlayerProps> = ({
    audioEnabled, audioPlaying, playlist, trackIndex, onUpdate, onSetTrackIndex, onSetAudioPlaying, onClearQueue
}) => {
    // Reference to the audio element
    const audioRef = useRef<HTMLAudioElement>(null);
    const [trackRefValue, setTrackRefValue] = useState<IAudioMessage | null>(null);
    const currentTrackRef = useRef<IAudioMessage | null>(null);
    const mediaSourceRef = useRef<MediaSource | null>(null);
    const sourceBufferRef = useRef<SourceBuffer | null>(null);

    useEffect(() => {
        if (audioRef.current && audioEnabled && playlist.length) {
            const firstTrackIndexToPlay = playlist.findIndex(itm => itm.lastPlayedAt === null)
            onSetTrackIndex(firstTrackIndexToPlay)
            currentTrackRef.current = playlist[firstTrackIndexToPlay]
            setTrackRefValue(playlist[firstTrackIndexToPlay])
        }
    }, [audioEnabled, playlist]);

    useEffect(() => {
        loadAudioStream()
    }, [trackRefValue])

    useEffect(() => {
        console.log('AUDIO PLAYER,,, ', {audioPlaying, audioEnabled})
        if (!audioPlaying || !audioEnabled) {
            onClearQueue()
            stopAudio()
        }
    }, [audioPlaying, audioEnabled])

    const playNextTrack = () => {
        console.log('onended triggerd')
        if (audioRef.current && audioEnabled) {
            if ( trackIndex < playlist.length - 1 ) {
                if ( currentTrackRef.current ) {
                    onUpdate(currentTrackRef.current.id, { lastPlayedAt: new Date() })
                }
                const newTrackIndex = playlist.findIndex(itm => itm.lastPlayedAt === null)
                console.log({newTrackIndex})
                onSetTrackIndex(newTrackIndex)
                currentTrackRef.current = playlist[newTrackIndex]
            } else {
                onSetAudioPlaying(false)
            }
        } else {
            onSetAudioPlaying(false)
        }
    }

    const playAudio = () => {
        if ( audioRef.current && audioEnabled && currentTrackRef.current) {
            audioRef.current.play().then((_) => {
                onSetAudioPlaying(true)
                console.log('audio isss playing')
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const stopAudio = () => {
        if ( audioRef.current ) {
            const isAudioPlaying = audioRef.current.currentTime > 0 && !audioRef.current.paused && !audioRef.current.ended 
                && audioRef.current.readyState > audioRef.current.HAVE_CURRENT_DATA;
            console.log({isAudioPlaying})
            if (isAudioPlaying) {            
                audioRef.current.pause();
            }
            audioRef.current.currentTime = 0;
            if ( currentTrackRef.current ) {
                onUpdate(currentTrackRef.current.id, { lastPlayedAt: new Date() })
            }
            // onSetAudioPlaying(false)
        }
    }

    const loadAudioStream = () => {
        console.log('load audio stream == ', {
            audioRef: audioRef.current, audioEnabled, currentTrack: currentTrackRef.current
        })
        // Initialize MediaSource
        if ( audioRef.current && audioEnabled && currentTrackRef.current ) {
            // commenting below 3 lines to use direct audio source for non streaming response
            // mediaSourceRef.current = new MediaSource();
            // mediaSourceRef.current.addEventListener('sourceopen', handleSourceOpen);
            // audioRef.current.src = URL.createObjectURL(mediaSourceRef.current);

            // adding  below line to use direct audio source
            audioRef.current.src = currentTrackRef.current.source;
            
            console.log('audio src was set to ', audioRef.current.src)
            setTimeout(playAudio, 300)
        }
    }

    const handleSourceOpen = async () => {
        const sourceBuffer = mediaSourceRef.current?.addSourceBuffer('audio/mpeg');
        sourceBufferRef.current = sourceBuffer ?? null;

        const response = await fetch(currentTrackRef.current?.source ?? '');
        const reader = response.body?.getReader();

        if (reader && sourceBufferRef.current) {
            const readData = async () => {
                const { done, value } = await reader.read();
                if (done && value === undefined) {
                    if (mediaSourceRef.current?.readyState === 'open') {
                        mediaSourceRef.current.endOfStream();
                    }
                }

                else if (value && sourceBufferRef.current) {
                    sourceBufferRef.current.appendBuffer(value);
                    sourceBufferRef.current.addEventListener('updateend', readData, { once: true });
                }
            };

            readData(); // Start reading the first chunk
        }
    };

    return (
        <audio
            ref={audioRef}
            preload="auto"
            onEnded={playNextTrack}
        />
    );
};

export default AudioPlayer;
