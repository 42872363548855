import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Spinner } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { IChatThreadObject, INewChartFormProps, INewChatFormValues } from '../../resources/interfaces/chat.interface';
import { newChatFormValidationSchema } from '../../resources/validators/chat.validator';
import FormTextInput from '../form-elements/textInput';
import { RadioGroup } from '../form-elements/radioInput';
import { useDispatch } from 'react-redux';
import { updateThreads } from '../../store/chat/chat.slice';
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom';
import { API_URLS, URLS } from '../../resources/constants';
import { useSelector } from 'react-redux';
import { getActiveAppId, getAppTheme } from '../../store/settings/settings.selectors';
import { useState } from 'react';
import { CallApi } from '../../saga/callApi';

const generateThreadName = () => {
  const currentdate = new Date();
  return "Chat On " + currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " @ "
    + currentdate.getHours() + ":"
    + currentdate.getMinutes() + ":"
    + currentdate.getSeconds();
}

const NewChatForm = ({ afterSubmit }: INewChartFormProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeAppId = useSelector(getActiveAppId)
  
  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm<INewChatFormValues>({
    resolver: yupResolver(newChatFormValidationSchema),
    defaultValues: {
      title: generateThreadName(),
      is_public: 'true'
    },
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const theme = useSelector(getAppTheme)
  const newThread = async (formValues: INewChatFormValues) => {

    setIsLoading(true)

    CallApi.POST(API_URLS.CHAT.THREAD.POST, {
      appId: activeAppId,
      is_public: formValues.is_public === 'true',
      title: formValues.title
    })
      .then((response: any) => {
        // console.log({addThread: response})
        setIsLoading(false)
        if (response?.data?.chat_thread_id) {
          const newThreadObject: IChatThreadObject = {
            chat_thread_id: response.data.chat_thread_id,
            is_public: response?.data?.is_public ?? formValues.is_public === 'true',
            title: response?.data?.title ?? formValues.title
          }
          dispatch(updateThreads(newThreadObject))
          afterSubmit && afterSubmit()
          navigate(`${newThreadObject.chat_thread_id}`)
        }
      }).catch((error: any) => {
        setIsLoading(false)
        console.log('thread error', error)
      })

  }

  return (
    <div className='new-chat-form-wrap'>
      <div className="intro" >Welcome! Please select the type of conversation you want to start, or select a thread from left to continue from where you left</div>
      <form onSubmit={handleSubmit(newThread)}>
        <div className="form-group">
          <label htmlFor="title">Give a conversation title</label>
          <Controller
            defaultValue=""
            control={control}
            name="title"
            render={({
              field: { onChange, onBlur, value, name, ref },
            }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.title}
                placeholder="Type a name"
              />
            )}
          />
        </div>
        <div className="form-group">
          <Controller
            control={control}
            name="is_public"
            render={({
              field: { onChange, onBlur, value, name, ref },
            }) => (
              <RadioGroup
                inline
                name={name}
                className='custom-radio-input'
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                options={[
                  {
                    label: 'Public',
                    value: 'true'
                  },
                  {
                    label: 'Private',
                    value: 'false'
                  }
                ]}
                error={errors.is_public}
              />
            )}
          />
        </div>
        <Button style={{backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor, borderColor: theme.otherTextColor}} disabled={isDirty && !isValid} variant="primary" type='submit'>{
          isLoading ? <Spinner animation="border" variant="light"></Spinner> : 'Add New'
        }</Button>
      </form>
    </div>
  )
}

export default NewChatForm