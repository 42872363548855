import { useEffect, useContext } from 'react';
import { TimerContext } from '../contexts/timer';

interface Timer {
    seconds: number;
    startTimer: () => void;
    stopTimer: () => void;
}

const useTimer = (): Timer => {
    const {timerRef, seconds, setSeconds} = useContext(TimerContext)

    useEffect(() => {
        return () => {
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        };
    }, []);

    const startTimer = () => {
        if ( timerRef.current === null ) {
            timerRef.current = setInterval(() => {
                setSeconds((prevSeconds: number) => prevSeconds + 1);
            }, 1000);
        }
    }

    const stopTimer = () => {
        setSeconds(0)
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }    

    return {
        seconds,
        startTimer,
        stopTimer,
    };
};

export default useTimer;
