import { Form } from "react-bootstrap"
import { IFormRadioGroupInputProps, ILabelValuePair } from "../../resources/interfaces/form-elements/radioInput.interface"
import { v4 as uuidv4 } from 'uuid'
import React from "react"
import { useSelector } from "react-redux"
import { getAppTheme } from "../../store/settings/settings.selectors"

export const RadioGroup = ({
  inputRef,
  value,
  error,
  options,
  ...props
}: IFormRadioGroupInputProps) => {
  const theme = useSelector(getAppTheme)
  const style = { "background-color": theme.otherBackgroundColor, "border-color": theme.otherBackgroundColor} as React.CSSProperties;
  return (
    <>
      {options.map((opt: ILabelValuePair, i: number) => {
        return (
        <React.Fragment key={uuidv4()}>
          <Form.Check
            {...props}
            ref={inputRef}
            checked={opt.value === value}
            type='radio'
            label={opt.label}
            value={opt.value || ''}
            id={`${props?.name}_${i}`}          
          />
        </React.Fragment>
      )})}
    </>
  )
}
