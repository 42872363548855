import { combineReducers } from 'redux'
import { reducer as user } from './user/user.slice'
import { reducer as appLoader } from './appLoader/appLoader.slice'
import { reducer as chats } from './chat/chat.slice'
import { reducer as settings } from './settings/settings.slice'
const reducers = {
  user,
  appLoader,
  chats,
  settings
}

export const appReducer = combineReducers(reducers)
