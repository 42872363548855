import React from "react";
import { Socket } from "socket.io-client";
import { SocketContext } from "../contexts/socket"


export const useSocket = (): Socket => {
    const socket = React.useContext(SocketContext);
    if (!socket) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return socket;
};