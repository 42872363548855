import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISettings, ISettingsLangauge, ISettingsTheme } from '../../resources/interfaces/settings.interface'
import { stat } from 'fs'

export const initialState: ISettings = {
  activeAppId: null,
  appState: null,
  authApiKey: null,
  isPublic: true,
  sidebarOpen: true,
  audioOutput: false,
  language: {
    source: "en-US",
    target: "en-US-KaiNeural"
  },
  phraseHints: [],
  theme: {
    appTitle: "",
    examples: "",
    logoURL: "",
    copyRightText: "",
    headerBackGroundColor: "",
    headerTextColor: "",
    footerBackgroundColor: "",
    footerTextColor: "",
    otherBackgroundColor: "",
    otherTextColor: "",
    menuBackgroundColor: "",
    menuTextColor: ""
  } 
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateAppType(state: ISettings, action: PayloadAction<boolean>) {
      state.isPublic = action.payload
    },
    setAppState(state: ISettings, action: PayloadAction<string | null>) {
      state.appState = action.payload
    },
    setAppTheme(state: ISettings, action: PayloadAction<ISettingsTheme>) { 
        state.theme.appTitle = action.payload.appTitle
        state.theme.examples = action.payload.examples
        state.theme.logoURL = action.payload.logoURL
        state.theme.copyRightText = action.payload.copyRightText
        state.theme.menuBackgroundColor = action.payload.menuBackgroundColor
        state.theme.menuTextColor = action.payload.menuTextColor
        state.theme.headerBackGroundColor = action.payload.headerBackGroundColor
        state.theme.headerTextColor = action.payload.headerTextColor
        state.theme.footerBackgroundColor = action.payload.footerBackgroundColor
        state.theme.footerTextColor = action.payload.footerTextColor
        state.theme.otherBackgroundColor = action.payload.otherBackgroundColor
        state.theme.otherTextColor = action.payload.otherTextColor
    },
    setPhraseHints(state: ISettings, action: PayloadAction<any>) {
      state.phraseHints = action.payload
    },
    setActiveAppId(state: ISettings, action: PayloadAction<string | null>) {
      state.activeAppId = action.payload
    },
    setVoiceLanguage(state: ISettings, action: PayloadAction<ISettingsLangauge>) {
      state.language = action.payload
    },
    setSidebarVisible(state: ISettings, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
    },
    setAuthApiKey(state: ISettings, action: PayloadAction<string | null>) {
      state.authApiKey = action.payload
    },
    setAudioOutput(state: ISettings, action: PayloadAction<boolean>) {
      state.audioOutput = action.payload
    }
  },
})
 
export const { reducer } = settingsSlice

export const {
  updateAppType,
  setAppState,
  setActiveAppId,
  setAppTheme,
  setVoiceLanguage,
  setSidebarVisible,
  setAuthApiKey,
  setAudioOutput,
  setPhraseHints
} = settingsSlice.actions
