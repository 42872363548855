import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { ILoginFormValues, ILoginPageProps, CompanyResponse, CompanyResponseMsg, CompanyResponseCompanyInfo } from "../../resources/interfaces/login.interface";
import FormTextInput from "../../components/form-elements/textInput";
import { CheckboxInput } from "../../components/form-elements/checkboxInput";
import { loginFormValidationSchema } from "../../resources/validators/login.validator";
import { Spinner } from "react-bootstrap";
import PasswordInput from "../../components/form-elements/passwordInput";
import FormSelectInput from "../../components/form-elements/selectInput";
import { CallApi } from "../../saga/callApi";
import { API_URLS } from "../../resources/constants";
import { authenticateUser, updateAccessToken, updateUser } from "../../store/user/user.slice";

const Login: React.FC<ILoginPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [companyOptions, setCompanyOptions] = useState<any[]>([])
  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm<ILoginFormValues>({
    resolver: yupResolver(loginFormValidationSchema),
    defaultValues: {
      username: '',
      password: '',
      company: {},
      keep_session: false
    },
  })
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const loginFormSubmitHandler = async (formValues: ILoginFormValues) => {
    setIsLoading(true)
    // console.log({ formValues })

    // check if company api_key available in company object
    if (Object.keys(formValues.company).length === 0) {
      // company is null. Call company api
      // const companyResponse: CompanyResponse = await 

      CallApi.POST(API_URLS.USER.COMPANY, formValues)
        .then((companyResponse: any) => {
          if (companyResponse.data.msg.successful) {
            if (companyResponse.data.msg.companyInfo.length == 1) {
              formValues['api_key'] = companyResponse.data.msg.companyInfo[0].serviceToken;
              formValues['company_name'] = companyResponse.data.msg.companyInfo[0].companyName;
              CallApi.POST(API_URLS.USER.LOGIN, formValues)
                .then((loginResponse: any) => {
                  if (loginResponse.data.token && loginResponse.data.token.length > 0) {
                    dispatch(updateUser(loginResponse.data))
                    dispatch(authenticateUser(true))
                    dispatch(updateAccessToken(loginResponse.data.token))
                    // navigate("/chat/" + result.app_id + "/2");
                  }
                }).then((error: any) => {
                  console.log('login api error', error)
                })

            } else {
              // console.log('list companies')
              setCompanyOptions(companyResponse.msg.companyInfo.map((k: any) => ({ ...k, label: k.company_name, value: k })))
            }
          } else {
            // console.log('company response no successufl')
          }
        }).catch((error: any) => {
          console.log('company api error', error)
        })


    } else {
      //TODO: 
      formValues['api_key'] = formValues.company.apiKey
      formValues['company_name'] = formValues.company.companyName
      CallApi.POST(API_URLS.USER.LOGIN, formValues).then((loginResponse: any) => {
        // console.log(loginResponse);
        if (loginResponse.token && loginResponse.token.length > 0) {
          dispatch(updateUser(loginResponse))
          dispatch(authenticateUser(true))
          dispatch(updateAccessToken(loginResponse.token))
        }
      }).then((error: any) => {
        console.log('login api error else', error)
      })

    }
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="logo-wrap">
          <Link to="#">
            <img alt="" src="/images/best-brain.png" />
          </Link>
        </div>
        <div className="login-content">
          <h3 className="welcome-text">
            Welcome to <strong>BestBrain</strong>
          </h3>
          <form onSubmit={handleSubmit(loginFormSubmitHandler)}>
            <div className="login-form">
              <div className="form-group">
                <label htmlFor="username">Email</label>
                <Controller
                  defaultValue=""
                  control={control}
                  name="username"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <FormTextInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="text"
                      error={errors.username}
                      placeholder="Enter your email address"
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Controller
                  defaultValue=""
                  control={control}
                  name="password"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <PasswordInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type={'password'}
                      error={errors.password}
                      placeholder="Enter your password"
                    />
                  )}
                />
              </div>
              {companyOptions.length > 0 ? (
                <div className="form-group">
                  <Controller
                    defaultValue=""
                    control={control}
                    name="company"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <FormSelectInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        options={companyOptions}
                        error={errors.company}
                        placeholder="Select Company"
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
            <div className="remember-me">
              <label className="container-checkbox">
                Remember Me
                <Controller
                  control={control}
                  name="keep_session"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CheckboxInput
                      name={name}
                      id={name}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      error={errors.keep_session}
                      checked={value}
                      className="custom-control-input"
                    />
                  )}
                />
              </label>
            </div>
            <div className="btn-wrap">
              <button type="submit" className="btn-submit">{
                isLoading ? <Spinner animation="border" variant="light"></Spinner> : 'Submit'
              }</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
