import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Footer from "../footer";
import SideNavbar from "../sidebar";
import { clearChat, setActiveSessionId } from "../../store/chat/chat.slice";
import { Modal } from "react-bootstrap";
import NewChatForm from "../../components/chat/newChatForm";
import { useSelector } from "react-redux";
import { getActiveSessionId, getActiveThreadObject } from "../../store/chat/chat.selectors";
import { API_URLS, URLS } from "../../resources/constants";
import { getActiveAppId, getAppTheme, getAppType, getLanguage, sidebarVisible } from "../../store/settings/settings.selectors";
import { authenticateUser, updateAccessToken, updateUser } from "../../store/user/user.slice";
import LanguageSelect from "../../components/chat/selectLang";
import Examples from "../../components/chat/examples";
import { setSidebarVisible } from "../../store/settings/settings.slice";
import { setAudioOutput } from '../../store/settings/settings.slice';
import { CallApi } from "../../saga/callApi";

const Header: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const isPublicApp = useSelector(getAppType);
  const activeAppId = useSelector(getActiveAppId)
  const sessionId = useSelector(getActiveSessionId)
  const language = useSelector(getLanguage)
  const theme = useSelector(getAppTheme)
  const isSidebarVisible = useSelector(sidebarVisible)
  const { threadId } = useParams();
  const activeThreadObject = useSelector(getActiveThreadObject(threadId ?? ''))
  const [showNewChatModal, setShowNewChatModal] = useState<boolean>(false)
  const [showLangSelectModal, setShowLangSelectModal] = useState<boolean>(false)
  const [showExampleModal, setShowExampleModal] = useState<boolean>(false)
  const [audioOn, setAudioOn] = useState<boolean>(false);
  const clearActiveChat = (evt: React.MouseEvent) => {
    evt.preventDefault()
    threadId && dispatch(clearChat({ threadId }))
  }

  const setAudio = (audioState: boolean) => {
    setAudioOn(audioState)
    dispatch(setAudioOutput(audioState))
    if (activeAppId) {
      // Call Init API and get a new session
      
      CallApi.PUT(API_URLS.CHAT.UPDATE_INIT, {
        appId: activeAppId,
        session_id: sessionId,
        type: audioState ? 'audio' : 'text',
        language: language
      })
        .then((resp: any) => {
          // console.log({ initApi: resp })
          if (resp?.data?.session_id) {
            // setActiveThreadId(resp.data.session_id)
            dispatch(setActiveSessionId(resp.data.session_id))
          }
        }).catch((error: any) => {
          console.log('init api error', error)
        })
    }
  }

  const doLogout = (evt: React.MouseEvent) => {
    evt.preventDefault()
    dispatch(updateUser(null))
    dispatch(authenticateUser(false))
    dispatch(updateAccessToken(null))
    isPublicApp ? navigate('') : navigate(URLS.LOGIN)
  }

  const toggleSidebar = (evt: React.MouseEvent) => {
    evt.preventDefault()
    dispatch(setSidebarVisible(!isSidebarVisible))
  }

  return (
    <>
      <SideNavbar />
      <div className="main-container">
        <div className="top-header">
          <div className="header-left">
            {activeThreadObject?.title}
          </div>
          <div className="header-right">
            {
              activeThreadObject ? (
                <React.Fragment>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} className="mobile-menu" onClick={toggleSidebar}><i className="fa-solid fa-bars"></i></button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={() => setShowLangSelectModal(true)}><i className="fa-solid fa-language"></i></button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={() => setShowExampleModal(true)}><i className="fa-solid fa-bars-staggered"></i></button>
                  {/* <button><i className="fa-solid fa-sliders"></i></button> */}

                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} className="btn-new-chat" onClick={() => setShowNewChatModal(true)}>
                    {/* <i className="fa-solid fa-plus"></i> New Chat */}
                    <i className="fa-solid fa-comment-medical"></i>
                  </button>
                  <button style={{ backgroundColor: theme.otherTextColor, color: theme.otherBackgroundColor }} onClick={() => setAudio(!audioOn)}>
                    {audioOn ? (
                      <i className="fa-solid fa-volume-high"></i>
                    ) : (
                      <i className="fa-solid fa-volume-xmark"></i>
                    )}
                  </button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={clearActiveChat} className="btn-clear-chat">
                    {/* <i className="fa-solid fa-eraser"></i> Clear Chat */}
                    <i className="fa-solid fa-eraser"></i>
                  </button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={doLogout}> <i className="fa-solid fa-sign-out"></i></button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} className="mobile-menu"><i className="fa-solid fa-bars"></i></button>
                  <button style={{ backgroundColor: theme.otherTextColor, color: theme.otherBackgroundColor }} onClick={() => setAudio(!audioOn)}>
                    {audioOn ? (
                      <i className="fa-solid fa-volume-high"></i>
                    ) : (
                      <i className="fa-solid fa-volume-xmark"></i>
                    )}
                  </button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={() => setShowLangSelectModal(true)}><i className="fa-solid fa-language"></i></button>
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={() => setShowExampleModal(true)}><i className="fa-solid fa-bars-staggered"></i></button>
                  {/* <button><i className="fa-solid fa-sliders"></i></button> */}
                  <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} onClick={doLogout}> <i className="fa-solid fa-sign-out"></i></button>
                </React.Fragment>
              )
            }
          </div>
        </div>
        <Outlet />
        <Footer />
        <Modal show={showNewChatModal} onHide={() => setShowNewChatModal(false)}>
          <NewChatForm afterSubmit={() => setShowNewChatModal(false)} />
        </Modal>
        <Modal show={showLangSelectModal} onHide={() => setShowLangSelectModal(false)}>
          <LanguageSelect onHide={() => setShowLangSelectModal(false)}></LanguageSelect>
        </Modal>
        <Modal show={showExampleModal} onHide={() => setShowExampleModal(false)}>
          <Examples></Examples>
        </Modal>

      </div>
    </>
  );
};

export default Header;
