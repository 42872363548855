import React from "react"
import { IFormCheckboxInputProps } from "../../resources/interfaces/form-elements"
import FormError from "./formError"

export const CheckboxInput = ({ ...props }: IFormCheckboxInputProps) => {
    return (
        <React.Fragment>
            <input
                type="checkbox"
                defaultValue={props.defaultValue}
                defaultChecked={props.defaultValue}
                ref={props.inputRef}
                // checked={props.value}
                onChange={evt => props.onChange(evt.target.checked)}
                className={
                    props.className
                        ? props.className
                        : `text-input ${props.error?.message ? 'text-input-error' : ''}`
                }
                disabled={props.disabled || false}
                id={props.id || props.name}
                autoFocus={props.autoFocus || false}
                checked={props.checked || false}
            />
            <span className="checkmark"></span>
            <FormError error={props.error} />
        </React.Fragment>
    )
}
