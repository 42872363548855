import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChatAudio, IChatMessageUpdate, IChatSliceAudioHistoryState, IChatSliceAudioState, IChatSliceClear, IChatSliceSetHistory, IChatSliceState, IChatSliceUpdate, IChatThreadObject, IMessage } from '../../resources/interfaces/chat.interface'
import { v4 as uuidv4 } from 'uuid'

export const initialState: IChatSliceState = {
  activeSessionId: undefined,
  activeThread: null,
  conversations: {
    default: []
  },
  threads: [],
  isAudioPlaying: false,
  isSessionEnded: false
}

const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setActiveSessionId(state: IChatSliceState, action: PayloadAction<string | undefined>) {
      // console.log("chat slice: state", state, "acion: ", action);
      if(action.payload) // make sure its a valid session id before updating redux
        state.activeSessionId = action.payload
    },
    setActiveThreadObject(state: IChatSliceState, action: PayloadAction<string>) {
      state.activeThread = state.threads.find(thread => thread.chat_thread_id === action.payload)
    },
    updateThreads(state: IChatSliceState, action: PayloadAction<IChatThreadObject>) {
      state.threads = [...state.threads, action.payload]
    },
    setThreadList(state: IChatSliceState, action: PayloadAction<IChatThreadObject[]>) {
      state.threads = action.payload
    },
    updateChat(state: IChatSliceState, action: PayloadAction<IChatSliceUpdate>) {
      if ( state.conversations[action.payload.threadId] ) {
        state.conversations[action.payload.threadId] = [ ...state.conversations[action.payload.threadId], action.payload.message ]
      } else {
        state.conversations[action.payload.threadId] = [action.payload.message]
      }
    },
    updateMessage(state: IChatSliceState, action: PayloadAction<IChatMessageUpdate>) {
      if ( state.conversations[action.payload.threadId] ) {
        const oldMessageIndex = state.conversations[action.payload.threadId].findIndex(
          (el) => (el.id === action.payload.messageId)
        )
        if ( oldMessageIndex > -1 ) {
          state.conversations[action.payload.threadId][oldMessageIndex] = {
            ...state.conversations[action.payload.threadId][oldMessageIndex],
            data: {
              ...state.conversations[action.payload.threadId][oldMessageIndex].data,
              ...action.payload.data
            }
          }
        }
      }
    },
    setChatHistory(state: IChatSliceState, action: PayloadAction<IChatSliceSetHistory>) {
      state.conversations[action.payload.threadId] = action.payload.messages
    },
    clearChat(state: IChatSliceState, action: PayloadAction<IChatSliceClear>) {
      state.conversations[action.payload.threadId] = []
    },
    setBotIsTyping(state: IChatSliceState, action: PayloadAction<{ threadId: string, value: boolean }>) {
      // console.log({ st: state.conversations[action.payload.threadId], payload: action.payload })
      if (state.conversations[action.payload.threadId]) {
        if (action.payload.value) {
          state.conversations[action.payload.threadId] = [ ...state.conversations[action.payload.threadId], {
            id: uuidv4(),
            sender: 'bot',
            data: {
              bb_type: 'typing'
            }
          }
        ]
        } else {
          // console.log('else')
          state.conversations[action.payload.threadId] = state.conversations[action.payload.threadId].filter(
            (m: IMessage) => (m.data.bb_type !== 'typing')
          )
        }
      }
    },
    setIsAudioPlayerPlaying(state: IChatSliceState, action: PayloadAction<boolean>) {
      state.isAudioPlaying = action.payload
    },
    setIsSessionEnded(state: IChatSliceState, action: PayloadAction<boolean>) {
      state.isSessionEnded = action.payload
    },
  },
})

export const { reducer } = chatSlice

export const {
  setActiveThreadObject,
  updateChat,
  clearChat,
  updateThreads,
  setThreadList,
  setBotIsTyping,
  setActiveSessionId,
  updateMessage,
  setChatHistory,
  setIsAudioPlayerPlaying,
  setIsSessionEnded,
} = chatSlice.actions
