import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getActiveThreadObject, getChatThreads } from '../../store/chat/chat.selectors';
import { setBotIsTyping, setChatHistory, setThreadList, updateChat } from '../../store/chat/chat.slice';
import { IChatThreadObject, IMessage } from '../../resources/interfaces/chat.interface';
import { useSocket } from '../../resources/hooks/socketHook';
import { API_URLS, SOCKET_RECEIVE_CHANNEL } from '../../resources/constants';
import { CallApi } from '../../saga/callApi';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveAppId, sidebarVisible } from '../../store/settings/settings.selectors';
import { setSidebarVisible } from '../../store/settings/settings.slice';

const ChatHistory: React.FC = () => {
  const history = useSelector(getChatThreads)
  const navigate = useNavigate()

  const dropdownActions: any[] = [
    { label: 'Action', onClick: () => { } },
    { label: 'Another action', onClick: () => { } },
    { label: 'Something else', onClick: () => { } }
  ]
  const socket = useSocket()
  const dispatch = useDispatch()
  const { threadId } = useParams()
  const activeThreadObject = useSelector(getActiveThreadObject(threadId ?? ''))
  const activeAppId = useSelector(getActiveAppId)
  const isSidebarVisible = useSelector(sidebarVisible)

  // Use Effect for setting Thread list
  useEffect(() => {
    if (activeAppId) {
    CallApi.GET(API_URLS.CHAT.THREAD.GET(activeAppId))
      .then((threads: any) => {
        // console.log(threads)
        dispatch(setThreadList(threads.data))
      }).catch((error: any) => {
        console.log('thread error', error)
      })
    }
  }, [activeAppId])

  // Useeffect for setting chat history
  useEffect(() => {
    if ( activeAppId && activeThreadObject?.chat_thread_id ) {
      dispatch(setChatHistory({ threadId: activeThreadObject.chat_thread_id, messages: [] }))
      CallApi.GET(`${API_URLS.CHAT.HISTORY(activeAppId)}?thread_id=${activeThreadObject.chat_thread_id}`)
        .then((resp: any) => {
          if ( resp?.data && resp?.data?.data ) {
            const chatHistory = resp.data.data.map((item: any, index: number) => ({
              id: uuidv4(),
              sender: item.role,
              // data: { ...item.data, disableButtons: index < (resp.data.data.length - 1) ? true : false },
              data: { ...item.data, disableButtons: true },
              sentAt: new Date(item.ts)
            } as IMessage))
            dispatch(setChatHistory({ threadId: activeThreadObject.chat_thread_id, messages: chatHistory }))
          }
        })
        .catch((err: any) => {})
    }
  }, [activeAppId, activeThreadObject])
  
  // Use effect for updating thread wise chat messages
  useEffect(() => {
    // if (!socket) return;
    socket.on(SOCKET_RECEIVE_CHANNEL, (message: any) => {
      // console.log('hello', { message, threadId: message?.thread_id ?? message?.session_id ?? 'default' })
      dispatch(setBotIsTyping({ threadId: message.thread_id, value: false }))
      dispatch(updateChat({
        threadId: message?.thread_id ?? message?.session_id ?? 'default',
        message: {
          id: uuidv4(),
          sender: 'bot',
          data: {
            bb_type: message.data?.bb_type ?? 'output_text',
            bb_value: message.data?.bb_value ?? '',
            bb_items: message.data?.bb_items ?? [],
            bb_buttons: message.data?.bb_buttons ?? [],
            audio_file: message.data?.audio_file ?? ""
          },
          sentAt: new Date(),
        }
      }))
    });

    return () => {
      socket.off(SOCKET_RECEIVE_CHANNEL);
    };
  }, [socket]);

  const onThreadClick = (evt: React.MouseEvent, thread: IChatThreadObject) => {
    evt.preventDefault()
    if ( isSidebarVisible ) {
      dispatch(setSidebarVisible(false))
    }
    navigate(`${thread.chat_thread_id}`)
  }

  return (
    <div className="chat-history-wrap">
      <ul>
        <li className="heading">Yesterday</li>
        {
          history.map(thread => (
            <li
              key={uuidv4()}
              className={threadId && threadId === thread.chat_thread_id ? "selected" : ""}
            >
              <i className="fa-regular fa-message"></i>
              <span onClick={(evt) => onThreadClick(evt, thread)}>{thread.title}</span>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    dropdownActions.map(action => (<Dropdown.Item key={uuidv4()} onClick={action.onClick}>{action.label}</Dropdown.Item>))
                  }
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default ChatHistory