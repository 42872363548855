
import { all, takeLatest, takeEvery } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../resources/constants";
import * as UserSaga from './user'


export default function* rootSaga() {
    yield all([
        // takeLatest(SAGA_ACTIONS.USER.LOGIN, UserSaga.login),
        takeLatest(SAGA_ACTIONS.USER.LOGIN, UserSaga.login),
        
    ])
}