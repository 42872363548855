import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { IAppThemeContext, ISettingsTheme } from '../interfaces/settings.interface';

export const AppThemeContext = React.createContext<IAppThemeContext | null>(null);

export const AppThemeProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [theme, setTheme] = useState<ISettingsTheme>({
    appTitle: "",
    examples: "",
    logoURL: "",
    copyRightText: "",
    headerBackGroundColor: "",
    headerTextColor: "",
    footerBackgroundColor: "",
    footerTextColor: "",
    otherBackgroundColor: "",
    otherTextColor: "",
    menuBackgroundColor: "",
    menuTextColor: ""
  });
  const themeRef = useRef<ISettingsTheme | null>(theme)
  themeRef.current = theme
  const [layout, setLayout] = useState<'embed' | 'public'>('public')
  const layoutRef = useRef<'embed' | 'public'>(layout)
  layoutRef.current = layout

  const themeObject = useMemo(() => ({ theme, setTheme, layout, setLayout }), [theme])

  return (
    <AppThemeContext.Provider value={themeObject}>
      {children}
    </AppThemeContext.Provider>
  );
};
