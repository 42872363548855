
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// import { getSettings } from '../../store/settings/selectors'
// import { SettingsI } from '../../store/settings/types'
// import {getUser} from '../../store/user/selectors'
// import {UserI} from '../../store/user/types'
import { Link } from 'react-router-dom'

import { URLS } from '../../resources/constants'
import { getAppTheme } from '../../store/settings/settings.selectors'

const Footer: FC = () => {
    const today = new Date();
    const year = today.getFullYear();
    const theme = useSelector(getAppTheme);

    // const SettingsData = useSelector(getSettings);
    // const UserData = useSelector(getUser)

    const [settingsData, setSettingsData] = useState<null>(null)
    // const [userData, setUserData] = useState<UserI | null>(null)

    return (
        <div>
            <div className="footer">
                <div className="container">
                    <p>{theme.copyRightText}</p>
                </div>
            </div>
        </div>

    )
}

export default Footer;