import Select from 'react-select'
import FormError from './formError'
import { IFormSelectInputProps } from '../../resources/interfaces/form-elements'
import React from 'react'

const FormSelectInput = ({ error, ...props }: IFormSelectInputProps) => {

  return (
    <React.Fragment>
      <Select
        ref={props.inputRef}
        options={props.options}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        isMulti={props.isMulti || false}
        isClearable={props.isClearable || false}
        className={props.className ?? 'dropdown-custom'} // 'dropdown-custom'
        instanceId={props.id ?? props.name}
        autoFocus={props.autoFocus ?? false}
        defaultValue={props?.defaultValue}
        menuPortalTarget={props?.menuPortalTarget || null}
      />
      <FormError error={error} />
    </React.Fragment>
  )
}

export default FormSelectInput