import React from 'react'
import ChatSummary from './chatSummary'
import ActionBar from './actionBar'
import { IChatWindowOld } from '../../resources/interfaces/chat.interface'

const ChatWindowOld: React.FC<IChatWindowOld> = (props) => {
  return (
    <React.Fragment>
        <ChatSummary {...props}></ChatSummary>
        {/* <ActionBar {...props}></ActionBar> */}
    </React.Fragment>
  )
}

export default ChatWindowOld