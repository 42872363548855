import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import Router from './router'
import { SocketProvider } from './resources/contexts/socket';
import { SOCKET_SERVER_URL } from './resources/constants';
import AppLoader from './components/appLoader';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'

// Import Styles
import './App.css';
import './index.css';
import { TimerProvider } from './resources/contexts/timer';
import { ChatProvider } from './resources/contexts/chat';
import { AppThemeProvider } from './resources/contexts/appTheme';

const MainApp: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SocketProvider url={SOCKET_SERVER_URL}>
        <AppThemeProvider>
          <TimerProvider>
            <ChatProvider>
              <React.Fragment>
                <AppLoader />
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </React.Fragment>
            </ChatProvider>
          </TimerProvider>
        </AppThemeProvider>
      </SocketProvider>
    </PersistGate>
  </Provider>
)

export default MainApp;
