import React, { ReactElement, useMemo, useRef, useState } from 'react';

export const TimerContext = React.createContext<any>(null);

export const TimerProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
const timerRef = useRef<NodeJS.Timeout | null>(null);
const [seconds, setSeconds] = useState<number>(0);

const timerObject = useMemo(() => ({timerRef, seconds, setSeconds}), [timerRef, seconds])

  return (
    <TimerContext.Provider value={timerObject}>
      {children}
    </TimerContext.Provider>
  );
};
