
export const capitalizeFirstLetter = (string: string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
};
 
export const currencyFormat=(price: any)=> {
  price=String(price);
  if(price == null)
  {
    price = '00';
  }
  if (price) {
    return '$ ' + price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return "";
  }
}

export const parseValidAppId = (input?: string): string => {
  let result = ""
  if ( input && input.length) {
    const isValid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(input)
    if (isValid) result = input
  }
  // do some validation on input
  // return the parsed output
  return result
}