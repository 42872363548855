import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AppNotFound: React.FC = () => {
//     const [windowAppId, setWindowAppId] = useState((window as any).reactAppId)
//   console.log('Hi from appnotfound')
//   console.log('Widnow app id == ', windowAppId)
//   useEffect(() => {
//     console.log('useeffect: Widnow app id == ', windowAppId)
//   }, [windowAppId])
    return (
        <div className="no-app-found-wrap">
            <h3 className="info">
                This app does not exists or might have been deleted
            </h3>
        </div>

    )
}

export default AppNotFound