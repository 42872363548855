import React, { useEffect, useRef } from "react"
import { IFileUploadInputProps, ISelectedFileEntity } from "../../resources/interfaces/form-elements"
import { Button } from "react-bootstrap";

export const FileUploadInput = ({
    openPickerByDefault = false,
    allowedMimeTypes = '',
    allowedExtensions = '',
    multiple = false, // Default to single file upload
    onFileSelect,
    ...props
}: IFileUploadInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (openPickerByDefault && inputRef.current) {
            inputRef.current.click();
        }
    }, [openPickerByDefault]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files ? Array.from(event.target.files) : null;
        if (files && files.length > 0) {
            if (multiple) {
                const fileReaders = files.map(file => {
                    return new Promise<ISelectedFileEntity | null>((resolve) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve({
                            content: reader.result as ArrayBuffer,
                            name: file.name
                        });
                        reader.onerror = () => resolve(null);
                        reader.readAsArrayBuffer(file);
                    });
                });

                Promise.all(fileReaders).then(results => {
                    const validFiles = results.filter(result => result?.content !== null) as ISelectedFileEntity[];
                    if (onFileSelect) onFileSelect(validFiles);
                });
            } else {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    const binaryData = reader.result;
                    if (binaryData) onFileSelect([{
                        content: binaryData as ArrayBuffer,
                        name: file.name
                    }]);
                };
                reader.readAsArrayBuffer(file);
            }
        } else {
            // Invoke the cancellation handler if no file was selected
            onFileSelect([])
        }
    }

    const getAcceptAttribute = () => {
        const mimeTypes = allowedMimeTypes.split(',').map(type => type.trim());
        const extensions = allowedExtensions.split(',').map(ext => {
            if (ext.startsWith('.')) return ext;
            return `.${ext}`;
        });
        return [...mimeTypes, ...extensions].join(',');
    }

    return (
        <React.Fragment>
            <input
                type="file"
                ref={inputRef}
                accept={getAcceptAttribute()}
                onChange={handleChange}
                multiple={multiple}
                style={{ display: "none" }}
                className={props.className ?? "bb-file-upload-input"}
                disabled={props.disabled || false}
                id={props.id ?? props.name}
            />
            <Button
                variant="primary"
                onClick={() => inputRef.current?.click()}
                disabled={props.disabled || false}
                className="bb-file-upload-input chat-message-button"
            >
                {props.name ?? 'Choose Files'}
            </Button>
        </React.Fragment>
    )
}
