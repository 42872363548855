import axios from 'axios';
import { store } from '../store';
import { AUTH_API_KEY_HEADER } from '../resources/constants';

// axios.defaults.withCredentials = true;
const getAccessToken = () => {
  return store.getState().user.accessToken;
}

const getAuthApiKey = () => {
  return store.getState().settings.authApiKey;
}

export class CallApi {
  static GET(url: string, data: any = {}, isAuth: boolean = false, authApiKey: string | null = null) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers: any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if (authApiKey) {
        headers[AUTH_API_KEY_HEADER] = authApiKey
      } else {
        headers[AUTH_API_KEY_HEADER] = getAuthApiKey()
      }
      if (isAuth) {
        headers['Authorization'] = 'Bearer ' + token;
      }
      return axios({ method: 'GET', url, headers, data }).then((success: any) => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response);
      });
    });
  }

  static POST(url: string, data: any = {}, isAuth: boolean = false, authApiKey: string | null = null) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers: any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if (authApiKey) {
        headers[AUTH_API_KEY_HEADER] = authApiKey
      } else {
        headers[AUTH_API_KEY_HEADER] = getAuthApiKey()
      }
      if (isAuth) {
        headers['Authorization'] = 'Bearer ' + token;
      }

      return axios({ method: 'POST', url, headers, data })
        .then(async success => {
          return resolve(success);
        })
        .catch(error => {
          // console.log('POST error: ', error)
          return reject(error.response);
        });
    });
  }

  static PUT(url: string, data: any = {}, isAuth: boolean = false, authApiKey: string | null = null) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers: any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if (authApiKey) {
        headers[AUTH_API_KEY_HEADER] = authApiKey
      } else {
        headers[AUTH_API_KEY_HEADER] = getAuthApiKey()
      }
      if (isAuth) {
        headers['Authorization'] = 'Bearer ' + token;
      }
      return axios({ method: 'PUT', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        // console.log('PUT error: ', error)
        return reject(error.response);
      });
    });
  }

  static DELETE(url: string, data: any = {}, isAuth: boolean = false, authApiKey: string | null = null) {
    return new Promise((resolve, reject) => {
      const token = getAccessToken();
      let headers: any = {
        // "ngrok-skip-browser-warning": "69420",
      }
      if (authApiKey) {
        headers[AUTH_API_KEY_HEADER] = authApiKey
      } else {
        headers[AUTH_API_KEY_HEADER] = getAuthApiKey()
      }
      if (isAuth) {
        headers['Authorization'] = 'Bearer ' + token;
      }
      return axios({ method: 'DELETE', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        // console.log('DELETE error: ', error)
        return reject(error.response);
      });
    });
  }
}
