import DatePicker from "react-datepicker";
import classnames from 'classnames'
import { IDateInputProps } from '../../resources/interfaces/form-elements';
import FormError from './formError';
import "react-datepicker/dist/react-datepicker.css";

function DateInput({
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  placeholder,
  error,
  id,
  maxDate,
  minDate,
  disabled,
  dark,
  dateFormat = 'yyyy-MM-dd',
  className,
}: IDateInputProps) {

  const datePickerContainerClass = classnames({
    'bb-datepicker-container': true,
    'dark': dark,
  })

  return (
    <div className={datePickerContainerClass}>
      <DatePicker
        selected={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholderText={placeholder}
        name={name}
        id={id}
        disabled={disabled}
        ref={inputRef}
        maxDate={maxDate}
        minDate={minDate}
        showYearDropdown
        dateFormat={dateFormat}
        className={className ?? "form-control"}
        autoComplete="off"
        autoFocus={false}
      />
      <i className="fa fa-calendar-o datepicker-icon"></i>
      <FormError error={error} />
    </div>
  )
}

export default DateInput
