import React, { useEffect, useRef, useState } from 'react';
import Select, { components, OptionProps } from 'react-select';
import { Modal } from 'react-bootstrap';
import { ILabelValuePair } from '../../resources/interfaces/form-elements';
import { IDialogSelectProps } from '../../resources/interfaces/form-elements/dialogSelect.interface';

const CustomOption: React.FC<OptionProps<ILabelValuePair, false>> = (props) => (
    <components.Option {...props}>
        {props.data.label}
    </components.Option>
);

const DialogSelect: React.FC<IDialogSelectProps> = ({ options, onChange, dialogContainerId, dialogCentered, disabled }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectOptions, setSelectOptions] = useState<ILabelValuePair[]>([])
    const [selectedOption, setSelectedOption] = useState<ILabelValuePair | null>(null);
    const [inputValue, setInputValue] = useState('');
    const dialogContainerRef = useRef<HTMLElement | null>()

    useEffect(() => {
        if (dialogContainerId && document.getElementById(dialogContainerId) !== null ) {
            dialogContainerRef.current = document.getElementById(dialogContainerId)
        }
    }, [dialogContainerId])

    useEffect(() => {
        setSelectOptions(options.map(o => ({ label: o.label, value: o.value })))
    }, [options])

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    const handleChange = (option: ILabelValuePair | null) => {
        setSelectedOption(option);
        onChange(option);
        handleCloseModal();
    };

    const filteredOptions = selectOptions.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    return (
        <React.Fragment>
            <div className='bb-dialog-select-dropdown-wrap'>
                <Select
                    className='bb-dialog-select-input'
                    isDisabled={disabled}
                    value={selectedOption}
                    onMenuOpen={handleOpenModal}
                    placeholder="Click here to choose an option"
                    isClearable
                    options={selectOptions}
                    components={{
                        Menu: () => null,
                        DropdownIndicator: () => null,
                        ClearIndicator: () => null
                    }}
                />
            </div>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered={dialogCentered ?? false}
                dialogClassName="bb-dialog-select-modal-dialog"
                backdropClassName="bb-dialog-select-modal-backdrop"
                container={dialogContainerRef?.current}
            >
                <Modal.Body className="bb-dialog-select-modal-wrap">
                    <Select
                        isDisabled={disabled}
                        value={selectedOption}
                        onChange={handleChange}
                        options={filteredOptions}
                        onInputChange={handleInputChange}
                        isClearable
                        autoFocus
                        menuIsOpen
                        components={{
                            Option: CustomOption,
                            DropdownIndicator: () => null,
                            ClearIndicator: () => null
                        }}
                        placeholder="Search options..."
                    />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default DialogSelect;
