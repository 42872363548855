import React, { ReactElement } from 'react';
import io, { Socket } from 'socket.io-client';

export const SocketContext = React.createContext<Socket | null>(null);

export const SocketProvider: React.FC<{ url: string, children: ReactElement }> = ({ url, children }) => {
  const socket = React.useMemo(() => io(url), [url]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
