import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUser, getAuthenticated } from '../../store/user/user.selectors'
import { getAppType } from '../../store/settings/settings.selectors';

export function useAuthStatus() {

  const user = useSelector(getUser);
  const checkAuthenticated = useSelector(getAuthenticated);
  const isPublic = useSelector(getAppType);

  const authChecker = () => {
    return isPublic ? true : checkAuthenticated ? true : false
  }
  const [isAuthenticated, setAuthenticated] = useState(authChecker())

  useEffect(() => {
    setAuthenticated(authChecker())
  }, [user]);

  return isAuthenticated
}