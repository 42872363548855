const FormError = (props: any) => {
    return (
      <>
        {props?.error?.message ? (
          <p className="text-danger mt-2">
            <span>{props.error.message}</span>
          </p>
        ) : null}
      </>
    )
  }
  
  export default FormError