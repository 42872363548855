/* eslint-disable @typescript-eslint/no-empty-function */
import { Form, InputGroup } from 'react-bootstrap'
import { IFormTextInputProps } from '../../resources/interfaces/form-elements'
import FormError from './formError'
import React from 'react'

const FormTextInput = ({
  onChange,
  onBlur,
  onPaste,
  value,
  name,
  inputRef,
  type,
  as,
  placeholder,
  error,
  id,
  pattern,
  onFocus,
  // setValueAlias,
  maxLength,
  className,
  // bsClass,
  step,
  appendText,
  readOnly,
  disabled,
  autoComplete,
  autoFocus,
  onKeyDown,
  icon,
  onIconClick,
  min,
}: IFormTextInputProps) => {
  return (
    <React.Fragment>
      <Form.Control
        className={
          disabled
            ? 'disabled-input ' + (className ?? 'form-control')
            : className ?? 'form-control'
        }
        bsPrefix={className}
        type={type}
        as={as}
        name={name}
        value={value || ''}
        id={id}
        step={step}
        onFocus={onFocus}
        maxLength={maxLength}
        pattern={pattern}
        onWheel={(evt: any) => evt.target.blur()}
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        autoComplete={autoComplete ?? 'no-auto-complete'}
        autoFocus={autoFocus ?? false}
        onPaste={onPaste}
        readOnly={readOnly}
        disabled={disabled}
        onKeyDown={onKeyDown}
        min={min}
      />
      {appendText ? (
        <InputGroup.Text id="basic-addon2">{appendText}</InputGroup.Text>
      ) : null}
      {icon ? (
        <i onClick={onIconClick ? () => onIconClick() : () => {}} className={icon + ' input-icon'}></i>
      ) : null}
      <FormError error={error} />
    </React.Fragment>
  )
}

export default FormTextInput