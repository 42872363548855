import { ISettings } from '../../resources/interfaces/settings.interface'
import { RootState } from '../types'

export const getAppType = (state: RootState) => (state.settings as ISettings).isPublic

export const getAppState = (state: RootState) => (state.settings as ISettings).appState

export const getAppTheme = (state: RootState) => (state.settings as ISettings).theme

export const getActiveAppId = (state: RootState) => (state.settings as ISettings).activeAppId

export const getLanguage = (state: RootState) => (state.settings as ISettings).language

export const sidebarVisible = (state: RootState) => state.settings.sidebarOpen

export const getAudioOutput = (state: RootState) => state.settings.audioOutput

export const getPhraseHints = (state: RootState) => state.settings.phraseHints
