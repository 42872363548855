import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppLoader } from '../../resources/interfaces/appLoader.interface'

export const initialState: IAppLoader = {
  show: false
}

const appLoaderSlice = createSlice({
  name: 'appLoader',
  initialState,
  reducers: {
    setAppLoader(state: IAppLoader, action: PayloadAction<boolean>) {
      state.show = action.payload
    }
  },
})

export const { reducer } = appLoaderSlice

export const { setAppLoader } = appLoaderSlice.actions
