import { Routes, Route } from 'react-router-dom';
import { URLS } from '../resources/constants'
import PageNotFound from '../pages/pageNotFound'
import Login from '../pages/login'
import Header from '../layouts/header'
import PrivateRoutes from '../hoc/privateRoutes';
import PublicRoutes from '../hoc/publicRoutes';
import AppTypeIdentifierHOC from '../hoc/appTypeIdentifierHOC';
import ChatBox from '../pages/chatBox';
import PrivateChatWindow from '../pages/privateChat';
import PublicChatWindow from '../pages/publicChat';


const Router: React.FC = () => {
    return (
        <div>
            <Routes>
                {/* <Route element={<ChatBox />} path={URLS.CHATBOX} /> */}
                <Route element={<AppTypeIdentifierHOC />} path="/:appId?">
                    <Route element={<PrivateRoutes />}>
                        <Route element={<Header />}>
                            <Route element={<PrivateChatWindow />} path={URLS.HOME} />
                            <Route element={<PrivateChatWindow />} path={`:threadId?`} />
                        </Route>
                    </Route>
                    <Route element={<PublicRoutes />}>
                        <Route element={<PublicChatWindow />} path={URLS.HOME} />
                        <Route element={<Login />} path={URLS.LOGIN} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                </Route>
            </Routes>
        </div>
    )
}

export default Router;