import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../resources/interfaces/user.interface'

export const initialState: IUser = {
  details: {},
  isAuthenticated: false,
  accessToken:null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state: IUser, action: PayloadAction<any>) {
      state.details = action.payload
    },
    authenticateUser(state: IUser, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    updateAccessToken(state: IUser, action: PayloadAction<any>) {
      state.accessToken = action.payload
    }
  },
})

export const { reducer } = userSlice

export const { updateUser, authenticateUser,updateAccessToken } = userSlice.actions
