import React, { Component } from "react";
import Slider, { Settings } from "react-slick";
import { IOutPutCarouselProps, IOutputCarouselSlideProps } from "../../resources/interfaces/chat.interface";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "react-bootstrap";

const Slide = ({ id, title, description, buttons, imageUrl, onButtonClick, disableButtons }: IOutputCarouselSlideProps) => {
    return (
        <div id={id} key={`bb-slide-${id}`} className="bb-slider-item">
            <img className="bb-slider-image" src={imageUrl} alt="" />
            <div className="bb-slider-item-body">
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="bb-slider-button-wrap">
                    {
                        buttons?.map((btn, idx) => (
                            <Button
                                disabled={disableButtons}
                                className="bb-slider-button"
                                key={`bb-slider-btn_${id}_${idx}`}
                                variant="primary"
                                onClick={(e) => onButtonClick && onButtonClick(e, btn)}
                            >
                                <label>
                                    {
                                        btn.link
                                            ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-128c0-17.7-14.3-32-32-32L352 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z" /></svg>
                                            : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M205 34.8c11.5 5.1 19 16.6 19 29.2l0 64 112 0c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96l-96 0 0 64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z" /></svg>
                                    }
                                    {btn.label}
                                </label>
                            </Button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

const OutputCarousel: React.FC<IOutPutCarouselProps> = ({
    items, onItemButtonClick, disableButtons, minSlides, enableSwipe, appMode
}) => {

    const settings: Settings = {
        className: "bb-slider",
        centerMode: items.length > minSlides,
        centerPadding: "60px",
        infinite:  items.length > minSlides,
        slidesToShow: minSlides,
        swipe:  items.length > minSlides,
        // swipeToSlide: enableSwipe,
        arrows: false,
        dots: false,
        responsive: appMode == 'embed'?[
            {
                breakpoint: 2000,
                settings: {
                  slidesToShow: 1,
                  infinite: items.length > 1,
                  swipe: items.length > 1,
                  centerMode: items.length > 1,
                }
              },
        ]: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 0,
                infinite: items.length > 3,
                swipe: items.length > 3,
                centerMode: items.length > 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: items.length > 2,
                swipe: items.length > 2,
                centerMode: items.length > 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                infinite: items.length > 1,
                swipe: items.length > 1,
                centerMode: items.length > 1,
              }
            },
            {
              breakpoint: 420,
              settings: {
                slidesToShow: 1,
                centerMode: false,
                dots: true,
                infinite: items.length > 1,
                swipe: items.length > 1,
              }
            }
          ]
        // afterChange: (index: number) => console.log(`Slider Changed to: ${index + 1}`)
    };

    return (
        <div className="bb-slider-container">
            <Slider {...settings}>
                {
                    items.map((slide, index) => (
                        <Slide
                            id={`${index}`}
                            imageUrl={slide.image}
                            title={slide.title}
                            description={slide.description}
                            buttons={slide.bb_buttons}
                            onButtonClick={onItemButtonClick}
                            disableButtons={disableButtons}
                        />
                    )
                    )
                }
            </Slider>
        </div>
    );
}

export default OutputCarousel;
