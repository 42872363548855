export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const SOCKET_SERVER_URL: string = process.env.REACT_APP_SOCKET_SERVER_URL ?? 'http://localhost:9001'
export const SOCKET_EMIT_CHANNEL = process.env.REACT_APP_SOCKET_EMIT_CHANNEL ?? 'channel_chat'
export const SOCKET_RECEIVE_CHANNEL = process.env.REACT_APP_SOCKET_RECEIVE_CHANNEL ?? 'testChnl'
export const AZURE_SPEECH_TRANSLATE = process.env.AZURE_SPEECH_TRANSLATE ?? ""
export const AZURE_SPEECH_REGION = process.env.AZURE_SPEECH_REGION
export const INACTIVITY_TIMEOUT_SECONDS: number = parseInt(process.env.REACT_APP_INACTIVITY_TIMEOUT_SECONDS ?? '5')

export const URLS = {
    HOME: '',
    LOGIN: 'login',
    CHATBOX: '/chatbox'
}

export const API_URLS = {
    USER: {
        COMPANY: `${API_BASE_URL}/auth/company`,
        LOGIN: `${API_BASE_URL}/auth/login`,
        LOGOUT: `${API_BASE_URL}/users/logout`,
    },
    SETTINGS: (app_id:string | undefined) => {
        return `${API_BASE_URL}/settings/${app_id}`
    },
    CHAT: {
        INIT: `${API_BASE_URL}/auth/init`,
        UPDATE_INIT: `${API_BASE_URL}/auth/init/update`,
        THREAD: {
            GET: (app_id: string) => {
                return `${API_BASE_URL}/chat/${app_id}/threads`
            },
            POST: `${API_BASE_URL}/chat/threads`,
            PUT: (thread_id: string) => {
                return `${API_BASE_URL}/chat/threads/${thread_id}`
            },
            DELETE: (thread_id: string) => {
                return `${API_BASE_URL}/chat/threads/${thread_id}/destroy`
            }
        },
        HISTORY: (app_id: string) => {
            return `${API_BASE_URL}/chat/${app_id}/threads/histories`
        }
    }
}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: 'SAGA_ACTIONS.USER.LOGIN',
        LOGOUT: 'SAGA_ACTIONS.USER.LOGOUT'
    },
    LOADER: {
        SET_LOADER: 'ACTIONS/LOADER/SET_FP_STATUS',
    },
    CHAT: {
        INIT: 'ACTIONS/CHAT/INIT',
        UPDATE_CHAT: 'ACTIONS/CHAT/UPDATE_CHAT',
    }
}

export const CHATBOX_SESSION_STORE_KEY = 'bb-session-id'
export const CHATBOX_DRAG_POSITION_KEY = 'bb-drag-pos'
export const CHATBOX_AUDIO_AGENT_KEY = 'bb-audio-agent'
export const AUTH_API_KEY_HEADER = 'bb-api-key'
export const SST_THRESHOLD = 3500
export const APP_AGENTS = [
    // {
    //     'source': 'en-US',
    //     'target': 'en-US-kIfcKu9kr8RZrbz7H3ox',
    //     "label": "English - Male",
    //     "type": "Eleven",
    //     "gender": "male",
    //     "name": "Jack"
    // },
    // {
    //     'source': 'en-US',
    //     'target': 'en-US-FzV1qDk1i6MbsuwXpJ46',
    //     "label": "English - Female (Lia)",
    //     "type": "Eleven",
    //     "gender": "female",
    //     "name": "Emily"
    // },
    {
        'source': 'en-US',
        'target': 'en-US-LunaNeural',
        "label": "English - Female (Luna)",
        "type": "Azure",
        "gender": "female",
        "name": "Luna"
    },
    // {
    //     'source': 'en-US',
    //     'target': 'en-US-OYTbf65OHHFELVut7v2H',
    //     "label": "English - Female (Hope)",
    //     "type": "Eleven",
    //     "name": "Hope",
    //     "gender": "female"
    // }
]

export const APP_AUDIO_LANGUAGES = [
    // {
    //     'source': 'bn-IN',
    //     'target': 'bn-IN-TanishaaNeural',
    //     "label": "Bengali",
    // },
    {
        'source': 'en-US',
        'target': 'en-US-bIHbv24MWmeRgasZH58o',
        "label": "English - Male",
        "type": "Eleven",
    },
    {
        'source': 'en-IN',
        'target': 'en-US-FzV1qDk1i6MbsuwXpJ46',
        "label": "English - Female (Lia)",
        "type": "Eleven",
    },
    {
        'source': 'en-IN',
        'target': 'en-US-OYTbf65OHHFELVut7v2H',
        "label": "English - Female (Hope)",
        "type": "Eleven",
    },
    // {
    //     'source': 'en-US',
    //     'target': 'en-US-LunaNeural',
    //     "label": "English",
    // },
    
    // {
    //     'source': 'hi-IN',
    //     'target': 'hi-IN-SwaraNeural',
    //     "label": "Hindi",
    // },
    // {
    //     'source': 'ar-AE',
    //     'target': 'ar-AE-FatimaNeural',
    //     "label": "Arabic",
    // },
    // {
    //     'source': 'es-US',
    //     'target': 'es-US-PalomaNeural',
    //     'label': "Spanish",
    //     "type": "Azure",
    // },
    // {
    //     'source': 'fr-FR',
    //     'target': 'fr-FR-DeniseNeural',
    //     'label': "French",
    //     "type": "Azure",
    // },
    
]