import React from 'react';
import MainApp from './mainApp';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'

const App: React.FC = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MainApp />
        </PersistGate>
    </Provider>
)

export default App;
