import { Outlet, Navigate, useSearchParams } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { useAuthStatus } from '../../resources/hooks/authHook'
import { useSelector } from 'react-redux';
import { getAppType } from '../../store/settings/settings.selectors';
import PublicChatWindow from '../../pages/publicChat';

const PublicRoutes = () => {
    const isAuthenticated = useAuthStatus();
    const isPublic = useSelector(getAppType);

    return (
        isPublic ? <Outlet />  : isAuthenticated ? <Navigate to={URLS.HOME} /> : <Outlet />
    )
}

export default PublicRoutes