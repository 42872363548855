import React, {  } from 'react'
// import { getUser } from '../../store/user/selectors'
// import { getMappedAcc } from '../../store/user/selectors'
// import { getAccessToken } from '../../store/user/selectors'
// import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'


interface Props {

}

const PageNotFound: React.FC<Props> = () => {
    // const { userAllowance } = useUserApi();



    return (


        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="dashboard-wrap">
                    <div className="row">
                        404
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PageNotFound;