import React, { useRef } from 'react'
import { IDynamicFormElement, IDynamicFormProps, IDynamicFormValues } from '../../resources/interfaces/chat.interface'
import { Controller, RefCallBack, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import FormTextInput from '../form-elements/textInput'
import { Button } from 'react-bootstrap'
import FormSelectInput from '../form-elements/selectInput'
import { CheckboxInput } from '../form-elements/checkboxInput'
import PasswordInput from '../form-elements/passwordInput'
import DateInput from '../form-elements/dateInput'
import moment from 'moment';

const DynamicForm: React.FC<IDynamicFormProps> = ({ elements, onSubmit, disabled }) => {
    const {
        control,
        formState: { errors, isDirty },
        handleSubmit,
        setError,
        clearErrors
    } = useForm<IDynamicFormValues>({
        // resolver: yupResolver(loginFormValidationSchema),
        defaultValues: {},
    })
    const invalidFieldsRef = useRef<{ name: string, error: string, type: string }[]>([])

    const submitHandler = (formValues: IDynamicFormValues) => {
        // Do form validations
        elements?.forEach(formElement => {
            let isInvalid: boolean = false
            const key = formElement.name
            if ( formElement?.required && formElement.required > 0 &&
                (
                    !formValues[key]
                    || formValues[key] === null
                    || formValues[key] === undefined
                    || formValues[key] === ''
                    || (
                        formElement.type === 'select'
                        && formElement?.is_multiple
                        && formElement.is_multiple > 0
                        && formValues[key].length === 0
                    )
                )
            ) {
                invalidFieldsRef.current.push(
                    { name: key, error: `${key} is required`, type: 'required' }
                )
                isInvalid = true
            }
            if ( formElement?.validations?.length ) {
                formElement.validations.forEach((v) => {
                    const fieldRegex = new RegExp(v.validator.split('/')[1])
                    let fieldValue = formValues[key]
                    if (formElement.type === 'select') {
                        fieldValue = fieldValue.value
                    } 
                    const isFieldValid = fieldRegex.test(fieldValue)
                    if ( !isFieldValid ) {
                        invalidFieldsRef.current.push(
                            { name: key, error: v.error_message, type: 'pattern'}
                        )
                        isInvalid = true
                    }
                })
            }
            if (!isInvalid) {
                clearErrors(key)
                invalidFieldsRef.current = invalidFieldsRef.current.filter(
                    (field) => field.name !== key
                )
            }
        })
        if ( invalidFieldsRef.current.length === 0 ) {
            elements?.forEach((formElement) => {
                // convert date to string
                if (formElement.type === 'date') {
                    formValues[formElement.name] = moment(formValues[formElement.name]).utc().format(formElement?.format ?? 'YYYY-MM-dd')
                }
                // convert select inputs
                else if (formElement.type === 'select') {
                    if (formElement?.is_multiple && formElement.is_multiple > 0) {
                        formValues[formElement.name] = formValues[formElement.name].map((option: any) => option.value)
                    } else {
                        formValues[formElement.name] = formValues[formElement.name].value
                    }
                }
                // convert numbers from string to number
                else if (formElement.type === 'number') {
                    formValues[formElement.name] = Number(formValues[formElement.name])
                }
            })
            return onSubmit(formValues)
        } else {
            invalidFieldsRef.current.forEach((field) => {
                setError(field.name, { type: field.type, message: field.error })
            })
        }
    }

    const renderFormInput = (
        element: IDynamicFormElement,
        value: any,
        onChange: () => void,
        onBlur: () => void,
        ref: RefCallBack,
) => {
        switch (element.type) {
            case 'text':
            case 'number':
                return (
                    <FormTextInput
                        name={element.name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={element.type}
                        error={errors?.[element.name]}
                        disabled={disabled}
                        // placeholder="Enter your email address"
                    />
                )
            case 'password':
                return (
                    <PasswordInput
                        name={element.name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={element.type}
                        error={errors?.[element.name]}
                        disabled={disabled}
                        // placeholder="Enter your email address"
                    />
                )
            case 'select':
                return (
                    <FormSelectInput
                        name={element.name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        options={element.options ?? []}
                        error={errors?.[element.name]}
                        isMulti={element?.is_multiple && element.is_multiple > 0 ? true : false}
                        placeholder={`Select ${element.name}`}
                        disabled={disabled}
                      />
                )
            
            case 'checkbox':
                return (
                    <CheckboxInput
                      name={element.name}
                      id={uuidv4()}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      error={errors?.[element.name]}
                      checked={value}
                      className="custom-control-input"
                      disabled={disabled}
                    />
                )
            
            case 'date':
                const maxDate = element?.max_date ? moment(element.max_date, 'YYYY-MM-DD') : null;
                const minDate = element?.min_date ? moment(element.min_date, 'YYYY-MM-DD') : null;
                return (
                    <DateInput
                        name={element.name}
                        id={uuidv4()}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        error={errors?.[element.name]}
                        className="form-control"
                        maxDate={maxDate?.toDate()}
                        minDate={minDate?.toDate()}
                        disabled={disabled}
                    />
                )
        
            default:
                return (
                    <FormTextInput
                        name={element.name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={'text'}
                        error={errors?.[element.name]}
                        disabled={disabled}
                        // placeholder="Enter your email address"
                    />
                );
        }
    }

    return (
        <form name={`dynamic-form-for-message`} onSubmit={handleSubmit(submitHandler)}>
            <div className='dynamic-form-wrap'>
                {
                    elements ? elements.map((element) => (
                        <div key={uuidv4()} className="dynamic-form-element-wrap form-group">
                            <label htmlFor={element.name}>{element.label}{element?.required && element?.required > 0 ? '*' : ''}</label>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={element.name}
                                disabled={disabled}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => renderFormInput(element, value, onChange, onBlur, ref)}
                            />
                        </div>
                    )) : null
                }
                <Button disabled={disabled} type='submit' variant='primary'>Submit</Button>
            </div>
        </form>
    )
}

export default DynamicForm