import React, { useContext, useEffect, useRef, useState } from 'react'
import ActionBar from './actionBar'
import { IAudioMessage, IChatContext, IChatWindow } from '../../resources/interfaces/chat.interface'
import Message from './message'
import { INACTIVITY_TIMEOUT_SECONDS, SOCKET_RECEIVE_CHANNEL } from '../../resources/constants'
import { v4 as uuidv4 } from 'uuid';
import AudioPlayer from './audioPlayer'
import { ChatContext } from '../../resources/contexts/chat'
import { useSocket } from '../../resources/hooks/socketHook'
import useTimer from '../../resources/hooks/timerHook'
import { AppThemeContext } from '../../resources/contexts/appTheme'
import { IAppThemeContext } from '../../resources/interfaces/settings.interface'

const ChatWindow: React.FC<IChatWindow> = ({
    rootClass,
    messagesListClass,
    audioLanguage,
    audioOn,
    onInitSession,
    windowVariables
}) => {
    const {
        sessionId,
        messages,
        isSessionEnded,
        audioPlayerPlaying,
        setIsSessionEnded,
        setIsTyping,
        addNewMessage,
        clearChat,
        setAudioPlayerPlaying,
        setIsBotListening
    } = useContext(ChatContext) as IChatContext
    const { layout } = useContext(AppThemeContext) as IAppThemeContext
    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    const [audioPlaylist, setAudioPlaylist] = useState<IAudioMessage[]>([])
    const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(0)
    const socket = useSocket()
    const timer = useTimer()
    
    // console.log('chat window renderd === ', messages)
    // Use effect for updating thread wise chat messages
    useEffect(() => {
        // if (!socket) return;

        if (!sessionId) return;

        socket.on(SOCKET_RECEIVE_CHANNEL, (message: any) => {
            if (message.session_id === sessionId) {
                console.log('message = ', message)
                setIsTyping(false)
                setIsBotListening(true)
                timer.stopTimer()
                const newMessageId = uuidv4()
                addNewMessage({
                    id: newMessageId,
                    sender: 'bot',
                    data: {
                        bb_type: message.data?.bb_type ?? 'output_text',
                        bb_value: message.data?.bb_value ?? '',
                        bb_label: message.data?.bb_label ?? '',
                        bb_items: message.data?.bb_items ?? [],
                        bb_buttons: message.data?.bb_buttons ?? [],
                        audio_file: message.data?.audio_file ?? "",
                        bb_allowed_file_ext: message.data?.bb_allowed_file_ext ?? "",
                        bb_allowed_file_mime: message.data?.bb_allowed_file_mime ?? "",
                        bb_attribute_name: message.data?.bb_attribute_name ?? "",
                        bb_allow_multiple_files: message.data?.bb_allow_multiple_files ?? false,
                    },
                    sentAt: new Date(),
                })
                if (message.data?.audio_file) {
                    console.log('got audio file == ', message.data?.audio_file)
                    // if (messages.length > 0 && messages[messages.length - 1].sender === 'user') {
                    //     if (audioPlayerPlaying) {
                    //         stopAudioPlaying()
                    //     }
                    //     setAudioPlaylist([{
                    //         id: newMessageId,
                    //         source: message.data?.audio_file,
                    //         lastPlayedAt: null,
                    //         playedTime: null,
                    //         caption: null,
                    //         captionLanguage: null
                    //     }])
                    // } else {
                        setAudioPlaylist((prevPlaylist) => {
                            let updatedPlaylist = [...prevPlaylist, {
                                id: newMessageId,
                                source: message.data?.audio_file,
                                lastPlayedAt: null,
                                playedTime: null,
                                caption: null,
                                captionLanguage: null
                            }]
                            return updatedPlaylist
                        })
                    // }
                }
            }
        });

        return () => {
            socket.off(SOCKET_RECEIVE_CHANNEL)
        };
    }, [socket, sessionId]);

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    // useeffect for failsafe timer
    useEffect(() => {
        if (timer.seconds >= INACTIVITY_TIMEOUT_SECONDS) {
            timer.stopTimer()
            setAudioPlayerPlaying(false)
            clearAudioPlaylist()
            setIsTyping(false)
            setIsSessionEnded(true)
        }
    }, [timer.seconds])


    const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

    const updateAudioItem = (id: string, payload: any) => {
        const itemToUpdate = audioPlaylist.find(itm => itm.id === id)
        if (itemToUpdate) {
            setAudioPlaylist((prevList) => {
                const itemIndex = audioPlaylist.indexOf(itemToUpdate)
                const newList = [...prevList]
                newList[itemIndex] = { ...itemToUpdate, ...payload }
                return newList
            })
        }
    }

    const clearAudioPlaylist = () => setAudioPlaylist(
        (prevList) => [...prevList].map(itm => ({ ...itm, lastPlayedAt: new Date() }))
    )

    const stopAudioPlaying = () => {
        setAudioPlayerPlaying(false)
        clearAudioPlaylist()
    }

    const restartChatSession = (evt: React.MouseEvent) => {
        evt.preventDefault()
        setIsSessionEnded(false)
        clearChat()
        onInitSession && onInitSession()
    }

    return (
        <React.Fragment>
            <div className={rootClass ?? "main-action-part"} id='bb-chat-box-message-container'>
                <div className={messagesListClass ?? "chat-window-wrap"}>
                    {
                        messages.map(message => <Message
                            key={message.id}
                            id={message.id}
                            sender={message.sender}
                            data={message.data}
                            setAudioPlayerPlaying={setAudioPlayerPlaying}
                        />)
                    }
                    {isSessionEnded && (
                        <div className='bb-session-ended-wrap'>
                            <span>Your session has ended</span>
                            <button type="button" className="bb-session-restart" onClick={restartChatSession}>
                                Restart Session
                            </button>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            {layout === 'embed' ? (
                <div className="bb-chatbot-window-footer">
                    <ActionBar
                        onSpeakerOff={stopAudioPlaying}
                        language={audioLanguage}
                        initiateMic={
                            windowVariables?.bbAudioMicStatus && windowVariables.bbAudioMicStatus === 'on'
                        }
                    />
                </div>
            ) : (
                <ActionBar onSpeakerOff={stopAudioPlaying} language={audioLanguage} />
            )}

            <AudioPlayer
                audioEnabled={audioOn ?? false}
                playlist={audioPlaylist}
                trackIndex={currentTrackIndex}
                audioPlaying={audioPlayerPlaying}
                onUpdate={updateAudioItem}
                onSetTrackIndex={setCurrentTrackIndex}
                onSetAudioPlaying={setAudioPlayerPlaying}
                onClearQueue={clearAudioPlaylist}
            />
        </React.Fragment>
    )
}

export default ChatWindow