import { useDispatch } from 'react-redux';
import { ILanguageSelectProps } from '../../resources/interfaces/chat.interface';
import { setVoiceLanguage } from '../../store/settings/settings.slice';
import { useSelector } from 'react-redux';
import { getActiveAppId, getAppTheme, getAudioOutput } from '../../store/settings/settings.selectors';
import { CallApi } from '../../saga/callApi';
import { getActiveSessionId } from '../../store/chat/chat.selectors';
import { API_URLS, APP_AUDIO_LANGUAGES } from '../../resources/constants';
import { setActiveSessionId } from '../../store/chat/chat.slice';

const LanguageSelect = ({ onHide }: ILanguageSelectProps) => {
    const languages = [
        {
            'source': 'bn-IN',
            'target': 'bn-IN-TanishaaNeural',
            "label": "Bengali",
        },
        {
            'source': 'en-IN',
            'target': 'en-IN-NeerjaNeural',
            "label": "English - India",
        },
        {
            'source': 'en-US',
            'target': 'en-US-LunaNeural',
            "label": "English - US",
        },
        {
            'source': 'hi-IN',
            'target': 'hi-IN-SwaraNeural',
            "label": "Hindi",
        },
        {
            'source': 'ar-AE',
            'target': 'ar-AE-FatimaNeural',
            "label": "Arabic",
        },
        {
            'source': 'es-US',
            'target': 'es-US-PalomaNeural',
            'label': "Spanish"
        },
        {
            'source': 'fr-FR',
            'target': 'fr-FR-DeniseNeural',
            'label': "French"
        },
    ]
    const dispatch = useDispatch()
    const activeAppId = useSelector(getActiveAppId)
    const sessionId = useSelector(getActiveSessionId)
    const audioOutput = useSelector(getAudioOutput)
    const theme = useSelector(getAppTheme)

    const updateLang = (item: any) => {
        dispatch(setVoiceLanguage(item));
        // Call Init API and get a new session
        CallApi.PUT(API_URLS.CHAT.UPDATE_INIT, {
            appId: activeAppId,
            session_id: sessionId,
            type: audioOutput ? 'audio' : 'text',
            language: item
        }).then((resp: any) => {
            // console.log({ initApi: resp })
            if (resp?.data?.session_id) {
                // setActiveThreadId(resp.data.session_id)
                dispatch(setActiveSessionId(resp.data.session_id))
            }
        }).catch((error: any) => {
            console.log('init api error', error)
        }).finally(() => onHide())
    }
    return (
        <div className='new-chat-form-wrap pop-selection'>
            <div className="intro" style={{ backgroundColor: theme.otherBackgroundColor , color: theme.otherTextColor}}>Please select the language of your preference for voice chat!</div>
            <ul>
                {APP_AUDIO_LANGUAGES.map((item, index) => (
                    <li key={index}>
                        <a role="button" style={{ color: theme.otherBackgroundColor}} onClick={() => updateLang(item)} href="javascript:void(0)"><i className="fa-solid fa-angle-right"></i> {item.label}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default LanguageSelect