import { memo, useEffect, useRef } from 'react'
import { IChatSummary } from '../../resources/interfaces/chat.interface';
import Message from './message';
import { useSelector } from 'react-redux';
import { getChatMessages, getIsSessionEnded } from '../../store/chat/chat.selectors';
import { v4 as uuidv4 } from 'uuid';

const ChatSummary = memo(function ChatSummary({ threadId, sessionId, onRestartSession }: IChatSummary) {
  const chatMessages = useSelector(getChatMessages(threadId ?? sessionId ?? 'default'))
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const isSessionEnded = useSelector(getIsSessionEnded)

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

  useEffect(() => {
    scrollToBottom()
  }, [chatMessages])

  return (
    <div className="main-action-part">
      {/* <div className="no-data-wrap">
            <img src="/images/best-brain.png" />
            <div className="no-data">
                <h2>Welcome to Golden Entertainment! I am your virtual assistant..</h2>
                <p>What you would like to know.</p>
            </div>
        </div> */}
      <div className="chat-window-wrap">
        {
          chatMessages.map((message, index) => <Message setAudioPlayerPlaying={() => {}} key={message.id} id={message.id} sender={message.sender} data={message.data} />)
        }
        {isSessionEnded && (
          <div className='bb-session-ended-wrap'>
            <span>Your session has ended</span>
            <button type="button" className="bb-session-restart" onClick={(e) => onRestartSession ? onRestartSession(e) : () => null}>
              Restart Session
            </button>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </div>
  )
});

export default ChatSummary