import * as Yup from 'yup'

export const loginFormValidationSchema = Yup.object().shape({
  username: Yup.string()
      .required('Email is required')
      .test(
        'isValidIdentifier',
        'Must be a valid email',
        value => {
          // Validate if the identifier is a valid email
          return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)
        },
      ),
    password: Yup.string().min(8).required('Password is required'),
    company: Yup.object().optional(),
    apiKey: Yup.string().optional(),
    keep_session: Yup.boolean().optional(),
})